.heading-block {
    display: block;

    .heading-block-title {
        color: #343434;
        line-height: 1.3;
        font-size: 36px;
        font-weight: bold;
        margin-top: 21px;
        margin-bottom: 30px;
    }

    .heading-block-description {
        font-size: 18px;
        color: rgba( 102, 102, 102, 1 );
    }

    .heading-block-img {
        max-width: 100%;
    }

    .button-link {
        margin-bottom: 2rem;
    }
}

// heading-block - Featured Block

.featured-block {
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .featured-block-element {
        padding-top: 1rem;

        .featured-block-title {
            color: #343434;
            font-size: 22px;
            font-weight: bold;
            margin-top: 1rem;
        }

        .featured-block-description {
            font-size: 16px;
            color: rgba( 102, 102, 102, 1 );
        }

        .featured-block-icons {
            height: 46px;

            .featured-block-img {
                max-width: 100%;
            }
            
            .featured-block-icon {
                padding-right: 2rem;
            }
            
            .featured-block-info-icon {
                height: 30px;
                vertical-align: top;
            }
        }

    }
}

@media only screen and (min-width: 768px){

    .heading-block {

        .heading-block-title {
            font-size: 52px;
        }

        .button-link {
            margin-top: 2rem;
        }
    }

    /* Heading block - featured block */

    .featured-block {

        .featured-block-element {
            padding-top: 0;
        }
    }
    
}
