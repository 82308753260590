@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Source Sans Pro'),
    url('../../fonts/woff2/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* will be preloaded */ 
    url('../../fonts/woff/source-sans-pro-v13-latin-regular.woff') format('woff'),
    url('../../fonts/ttf/source-sans-pro-v13-latin-regular.ttf') format('truetype'),
    url('../../fonts/eot/source-sans-pro-v13-latin-regular.eot') format('embedded-opentype');
    unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Source Sans Pro'),
    url('../../fonts/woff2/source-sans-pro-v13-latin-700.woff2') format('woff2'), /* will be preloaded */ 
    url('../../fonts/woff/source-sans-pro-v13-latin-700.woff') format('woff'),
    url('../../fonts/ttf/source-sans-pro-v13-latin-700.ttf') format('truetype'),
    url('../../fonts/eot/source-sans-pro-v13-latin-700.eot') format('embedded-opentype');
    unicode-range: U+000-5FF; /* Latin glyphs */
}