@import "~bootstrap/scss/bootstrap";

/* Custom scss */
@import "./fonts";

body {
    font-size: 15px;
    line-height: 1.42857;
    font-family: "Source Sans Pro",Helvetica,Arial,sans-serif;
}

/* .background-bricks {
    background: url('/build/images/bg-brick-white.png') no-repeat;
    padding-top: 25px;
    padding-bottom: 25px;
} */

.background-bricks-purple {
    background: linear-gradient(rgba( 122, 64, 237, 0 ),rgba( 122, 64, 237, 0 )), rgba( 120, 62, 237, 1 ) url('/build/images/bg-brick-white.png') right top no-repeat;
}

.background-line.lazy-bg-loaded {
    background: url('/build/images/bg-line.png') right top no-repeat;
}

.background-gray {
    background: rgba( 245, 245, 245, 1 );
}

.button-link {
    background-color: rgba( 237, 121, 62, 1 );
    border-radius: 5px;
    color: rgba( 255, 255, 255, 1 );
    padding: 15px 50px 18px 50px;
    
    cursor: pointer;
    display: inline-block;
    text-decoration: none!important;
    text-align: center;
    
    font-size: 18px;
    font-weight: bold;
}

.button-link:visited {
    color: rgba( 255, 255, 255, 1 );
}

.button-link:hover {
    color: rgba( 255, 255, 255, 1 );
    background-color: rgba( 52, 52, 52, 1 );
}

/* Modals */
.modal-header, .modal-footer {
    border: 0;
}

/* Navbar */
.navbar {
    height: 80px;
}

.navbar-sticky {
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
}

.navbar-row {
    width: 100%;
}

.navbar-title {
    margin: auto;
    font-weight: 600;
    font-size: 2rem;
    color: rgba( 237, 121, 62, 1 );
}

.navbar-pages-list {
    padding: 0;
    list-style-type: none;
}

.navbar-pages-list-element {
    margin-right: 25px;
    display: inline-block;
}

.navbar-pages-list-element a {
    display: block;
    font-size: 15px;
    color: #343434;
    text-decoration: none;
    line-height: 30px;
}

#scrollUp {
    position: fixed;
    right: 2%;
    bottom: 3%;
    height: 45px;
    width: 40px;
    border-radius: 4px;
    background-color: rgba(35,35,35,0.65);
    text-align: center;
    cursor: pointer;
    z-index: 500;
    display: none;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    
    > svg {
        display: block;
        height: 100%;
        font-size: 22px;
        color: #fff;
        margin: 0 auto;
        width: 0.875em;
    }
}